import React from "react";

import * as styles from "../sass/components/iphone.module.scss";

const IPhone = ({width = 200, children}) => {
  return (
    <div className={styles.chrome} style={{width: width}}>
      <div className={styles.screen}>
        {children}
      </div>
      <div className={styles.device} />
    </div>
  );
}

export default IPhone;