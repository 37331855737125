import React, {useEffect, useState} from 'react';
import {Link, graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {HiOutlineDocumentText, HiOutlineArrowRight} from "react-icons/hi";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LineIcon
} from "react-share"

import Layout from "../containers/Layout";
import Meta from "../containers/Meta";
import IPhone from "../components/IPhone";

import appStoreButton from "../images/appstore.svg";
import screen1 from "../images/screenshots/screen1.png";
import screen2 from "../images/screenshots/screen2.png";
import screen3 from "../images/screenshots/screen3.png";
import screen4 from "../images/screenshots/screen4.png";
import screen5 from "../images/screenshots/screen5.png";
import icon from "../images/icon.png";
import qr from "../images/qr-code.jpg";

import * as styles from "../sass/index.module.scss";

const IndexPage = ({data}) => {

  const [screen2visible, setScreen2] = useState(false);
  const [screen3visible, setScreen3] = useState(false);
  const [screen4visible, setScreen4] = useState(false);
  const [screen5visible, setScreen5] = useState(false);

  const main1 = getImage(data.main1);
  const main2 = getImage(data.main2);
  const main3 = getImage(data.main3);
  const main4 = getImage(data.main4);

  useEffect(() => {
    setTimeout(() => {
      setScreen2(true);
    }, 3000);
    setTimeout(() => {
      setScreen3(true);
    }, 6000);
    setTimeout(() => {
      setScreen4(true);
    }, 9000);
    setTimeout(() => {
      setScreen5(true);
    }, 12000);
  })

  return (
    <Layout>
      <Meta title={"まもる 〜 子どもの置き去り防止バス安全確認アプリ"} />
      <div className={styles.hero}>

        <div className={styles.heroFlex}>
          <div className={styles.slogan}>
            <h1>みんなで子どもを<br />「まもる」</h1>
            <h2>スクールバスで<br />子供の置き去りリスクを低減する<br />安全確認アプリ</h2>

            <div className={styles.buttonDesktop}>
              <a href="https://apps.apple.com/jp/app/%E3%81%BE%E3%82%82%E3%82%8B/id1644113256?itscg=30200&amp;itsct=apps_box_appicon" >
                <img src={appStoreButton} alt="AppStoreで「まもる」をダウンロード" />
              </a>
              <img className={styles.qr} src={qr} alt="QRコード" />
            </div>

          </div>

          <div className={styles.iphone}>
            <IPhone width="80%">
              <div className={styles.iphoneContainer}>
                <img src={screen1} alt="" />
                <img className={screen2visible ? styles.visible : null} src={screen2} alt="" />
                <img className={screen3visible ? styles.visible : null} src={screen3} alt="" />
                <img className={screen4visible ? styles.visible : null} src={screen4} alt="" />
                <img className={screen5visible ? styles.visible : null} src={screen5} alt="" />
              </div>
            </IPhone>
          </div>
        </div>
      </div>

      <div className={styles.download}>
        <span className={styles.title}>
          <img src={icon} alt="まもるのダウンロードはこちらから" width={50} height={50} />
          <span>まもる</span>
        </span>
        <a href="https://apps.apple.com/jp/app/%E3%81%BE%E3%82%82%E3%82%8B/id1644113256?itscg=30200&amp;itsct=apps_box_appicon" >
          <img className={styles.banner} src={appStoreButton} alt="AppStoreで「まもる」をダウンロード" />
        </a>
      </div>

      <div className={styles.message}>
        <h1>置き去り防止のしくみ</h1>
        <p>
          このアプリはアメリカのスクールバスで取り入れられている置き去り防止策を参考に制作しました。<br />
          アメリカのスクールバスでは、
        </p>
        <ul>
          <li>エンジンを止めると車内にアラームが鳴る。</li>
          <li>ドライバーは、後部座席にある停止スイッチを押してアラームを止める。</li>
        </ul>
        <p>
          最後部まで行く間に、子供が残っていたら自然と目に入って気がつくというものです。<br />
          この「アラーム停止スイッチ」を、このアプリでは「バスの最後部からの写真を撮影するという行動」で代用しています。
        </p>

      </div>

      <div className={styles.features}>
        <h1>「まもる」の特徴</h1>

        <div className={styles.featuresFlex}>
          <div className={styles.feature}>
            <div className={styles.iphone}>
              {/*<img src={main1} alt="iPhoneスクリーンショット" />*/}
              <GatsbyImage className={styles.iphoneScreenshot} alt={"iPhoneスクリーンショット"} image={main1} />
            </div>
            <div className={styles.content}>
              <h2>コスト0で<br />今日から使える</h2>
              <p>
                この無料アプリを使えば特別な機材や設備は不要です。<br />必要なものはiPhoneだけ。
              </p>
            </div>
          </div>

          <div className={styles.feature}>
            <div className={styles.iphone}>
              <GatsbyImage className={styles.iphoneScreenshot} alt={"iPhoneスクリーンショット"} image={main2} />
            </div>
            <div className={styles.content}>
              <h2>ボタンを押すだけ<br />簡単報告</h2>
              <p>
                ドライバーはバス後方から写真を撮影し、報告完了。<br />
                3日分の運転記録が保存されます。
              </p>
            </div>
          </div>

          <div className={styles.feature}>
            <div className={styles.iphone}>
              <GatsbyImage className={styles.iphoneScreenshot} alt={"iPhoneスクリーンショット"} image={main3} />
            </div>
            <div className={styles.content}>
              <h2>報告忘れを<br />通知でお知らせ</h2>
              <p>
                もし設定時間内に報告が完了しない場合は、プッシュ通知で全員にお知らせします。
              </p>
            </div>
          </div>

          <div className={styles.feature}>
            <div className={styles.iphone}>
              <GatsbyImage className={styles.iphoneScreenshot} alt={"iPhoneスクリーンショット"} image={main4} />
            </div>
            <div className={styles.content}>
              <h2>QRコードで<br />簡単登録</h2>
              <p>
                管理者が発行したQRコードを読み取ることで、ドライバーはすぐに利用を始めることができます。
              </p>
            </div>
          </div>
        </div>

      </div>

      <div className={styles.functions}>
        <h1>このアプリでできること</h1>

        <div className={styles.functionsFlex}>
          <div className={`${styles.card} ${styles.themeAdmin}`}>
            <h2>管理者</h2>
            <ul>
              <li>バスの状況確認 (運転中・待機中・要確認)</li>
              <li>バスの登録</li>
              <li>ユーザー(ドライバー)の登録・招待</li>
              <li>運転記録の閲覧 (3日分)</li>
              <li>運転モード・バス点検報告</li>
            </ul>
          </div>

          <div className={`${styles.card} ${styles.themeDriver}`}>
            <h2>ドライバー</h2>
            <ul>
              <li>運転モード・バス点検報告</li>
              <li>バスの状況確認 (運転中・待機中・要確認)</li>
              <li>運転記録の閲覧 (3日分)</li>
            </ul>
          </div>
        </div>


      </div>

      <div className={styles.documents}>
        <Link to="/documents" className={styles.btn}>
          <span className={styles.icon}>
            <HiOutlineDocumentText />
          </span>
          <div className={styles.text}>
            <span>資料ダウンロード</span>
            <small>手元に置いてご覧いただくためのマニュアルと、保護者の皆様へ向けた導入のお知らせにお使いいただける雛形を用意いたしました。ぜひご活用ください。</small>
          </div>
          <span className={styles.chevron}><HiOutlineArrowRight /></span>
        </Link>
      </div>

      <div className={styles.share}>
        <p>このアプリが必要な方にひとりでも多く届くよう力をお貸しください！</p>
        <p className={styles.shareTitle}>このアプリをシェア</p>
        <div className={styles.shareButtons}>
          <TwitterShareButton url="https://mamoru-bus.com" title="まもる 〜 子どもの置き去り防止バス安全確認アプリ">
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
          <FacebookShareButton url="https://mamoru-bus.com" quote="まもる 〜 子どもの置き去り防止バス安全確認アプリ">
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>
          <LineShareButton url="https://mamoru-bus.com" title="まもる 〜 子どもの置き去り防止バス安全確認アプリ">
            <LineIcon size={40} round={true} />
          </LineShareButton>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
    query IndexQuery {
        main1: file(relativePath: { eq: "main1.png" }) {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
        }
        main2: file(relativePath: { eq: "main2.png" }) {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
        }
        main3: file(relativePath: { eq: "main3.png" }) {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
        }
        main4: file(relativePath: { eq: "main4.png" }) {
            childImageSharp {
                gatsbyImageData(width: 400)
            }
        }
    }
`

export default IndexPage;